
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, bugOutline, bugSharp, cloudDownloadOutline, cloudDownloadSharp, shareOutline, shareSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, chatbubblesOutline, chatbubblesSharp, informationCircleOutline, informationCircleSharp, shieldCheckmarkOutline, shieldCheckmarkSharp, starOutline, starSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, logoFacebook, logoTwitter, logoInstagram, logoYoutube, homeOutline, homeSharp, newspaperOutline, newspaperSharp, videocamOutline, videocamSharp, airplaneOutline, airplaneSharp, shirtOutline, shirtSharp, cartOutline, cartSharp, musicalNotesOutline, musicalNotesSharp, medicalOutline, medicalSharp, documentTextOutline, documentTextSharp, checkboxOutline, checkboxSharp, peopleOutline, peopleSharp, chatboxEllipsesOutline, chatboxEllipsesSharp } from 'ionicons/icons';
import { Plugins } from "@capacitor/core";
import { AppRate } from '@ionic-native/app-rate';
import axios from "axios";
import { jsonDataURL } from '@uinterview/uinterview'

export default defineComponent({
	name: 'App',
	components: {
		IonApp, 
		IonContent, 
		IonIcon, 
		IonItem, 
		IonLabel, 
		IonList, 
		IonListHeader, 
		IonMenu, 
		IonMenuToggle, 
		IonNote, 
		IonRouterOutlet, 
		IonSplitPane
	},
	setup() {
		const selectedIndex = ref(0);
		const appPages = [
			{
				title: 'Home',
				url: '/app/',
				iosIcon: homeOutline,
				mdIcon: homeSharp
			},
			{
				title: 'News',
				url: '/app/category/news',
				iosIcon: newspaperOutline,
				mdIcon: newspaperSharp
			},
			{
				title: 'Videos',
				url: '/app/category/videos',
				iosIcon: videocamOutline,
				mdIcon: videocamSharp
			},
			{
				title: 'Reviews',
				url: '/app/category/reviews',
				iosIcon: starOutline,
				mdIcon: starSharp
			},
			{
				title: 'Music',
				url: '/app/category/music',
				iosIcon: musicalNotesOutline,
				mdIcon: musicalNotesSharp
			},
			{
				title: 'Style',
				url: '/app/category/style',
				iosIcon: shirtOutline,
				mdIcon: shirtSharp
			},
			{
				title: 'uBio',
				url: '/app/category/ubio',
				iosIcon: peopleOutline,
				mdIcon: peopleSharp
			},
			{
				title: 'uShop',
				url: '/app/category/ushop',
				iosIcon: cartOutline,
				mdIcon: cartSharp
			},
			{
				title: 'uTravel',
				url: '/app/category/utravel',
				iosIcon: airplaneOutline,
				mdIcon: airplaneSharp
			},
/*
			{
				title: 'Coronavirus',
				url: '/app/category/coronavirus',
				iosIcon: medicalOutline,
				mdIcon: medicalSharp
			}
*/
		];
		const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
		const socials = [
		{
			name: 'Facebook',
			iosIcon: logoFacebook,
			mdIcon: logoFacebook,
			url:  'http://www.facebook.com/UInterview/'
		}, {
			name: 'Twitter',
			iosIcon: logoTwitter,
			mdIcon: logoTwitter,
			url:  'http://twitter.com/uinterview/'
		}, {
			name: 'Instagram',
			iosIcon: logoInstagram,
			mdIcon: logoInstagram,
			url:  'https://www.instagram.com/uinterview/'
/*
		}, {
			name: 'YouTube',
			iosIcon: logoYoutube,
			mdIcon: logoYoutube,
			url:  'https://www.youtube.com/channel/UCClKR595AEB9Ivt_PrsrKZQ/featured'
*/
		}];
		const path = window.location.pathname.split('page/')[1];
		if (path !== undefined) {
			selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
		}
		const path2 = window.location.pathname.split('category/')[1];
		if (path2 !== undefined) {
			selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path2.toLowerCase());
		}
		
		const route = useRoute();

		return { 
			selectedIndex,
			appPages, 
			labels,
			socials,
			shareOutline,
			shareSharp,
			homeOutline,
			homeSharp,
			newspaperOutline,
			newspaperSharp,
			videocamOutline,
			videocamSharp,
			shirtOutline,
			shirtSharp,
			airplaneOutline,
			airplaneSharp,
			cartOutline,
			cartSharp,
			musicalNotesOutline,
			musicalNotesSharp,
			medicalOutline,
			medicalSharp,
			documentTextOutline,
			documentTextSharp,
			checkboxOutline,
			checkboxSharp,
			peopleOutline,
			peopleSharp,
			chatboxEllipsesOutline,
			chatboxEllipsesSharp,
			logoFacebook,
			logoTwitter,
			logoInstagram,
			logoYoutube,
			archiveOutline, 
			bugSharp, 
			bugOutline, 
			cloudDownloadSharp, 
			cloudDownloadOutline, 
			archiveSharp, 
			bookmarkOutline, 
			bookmarkSharp, 
			heartOutline, 
			heartSharp, 
			mailOutline, 
			mailSharp, 
			chatbubblesOutline, 
			chatbubblesSharp, 
			informationCircleOutline, 
			informationCircleSharp, 
			shieldCheckmarkOutline, 
			shieldCheckmarkSharp, 
			starOutline, 
			starSharp, 
			paperPlaneOutline, 
			paperPlaneSharp, 
			trashOutline, 
			trashSharp, 
			warningOutline, 
			warningSharp,
			isSelected: (url: string) => url === route.path ? 'selected' : ''
		}
	},
	mounted() {
		const d = new Date
		this.$refs.copyYear.innerHTML = d.getFullYear()
		// this.$refs.adsenseContent.innerHTML = document.getElementById('divadsensedisplaynone').innerHTML
	},
	methods: {
		clearCache(){
			if(localStorage.appData) {
				console.log('cleaning cache');
				window.localStorage.removeItem('appData');
				axios.get(jsonDataURL)
					.then(response => {
						this.entriesList = response.data
						localStorage.setItem('appData', JSON.stringify(response.data));
						location.href = '/app/'
					})
			}
		},
		rateApp(){
			/*
			const preferences = AppRate.getPreferences();
			preferences.simpleMode = true;
			preferences.customLocale = {
				title: "Rate the %@ app!",
				message: "It won't take more than a minute, and you'll help us reach more people.",
				cancelButtonLabel: "No, Thanks",
				laterButtonLabel: "Remind Me Later",
				rateButtonLabel: "Rate It Now",
				yesButtonLabel: "Yes!",
				noButtonLabel: "Not really",
				appRatePromptTitle: "Rate the %@ app!",
				feedbackPromptTitle: 'Give us some feedback?',
			}
			preferences.storeAppURL = {
				ios: 'id548529935',
				android: 'market://details?id=com.uinterview.erik',
				// windows: 'ms-windows-store://review/?ProductId=XXXXXXXX',
			};
			AppRate.setPreferences(preferences);
			console.log(preferences)
			AppRate.promptForRating(true);
			*/
		},
		shareApp(){
			const shareOptions = {
				message: 'uInterview - Entertainment news straight from the source',
				subject: 'uInterview - Entertainment news straight from the source',
				files: ['', ''], // an array of filenames either locally or remotely
				url: 'https://uinterview.com/get-app/',
				chooserTitle: 'Share With', // Android only, you can override the default share sheet title
				iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
			};
			const shareOnSuccess = function(result) {
				console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
				console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
			};
			const shareOnError = function(msg) {
				console.log("Sharing failed with message: " + msg);
			};
			window['plugins'].socialsharing.shareWithOptions(shareOptions, shareOnSuccess, shareOnError);
		}
	}
});
