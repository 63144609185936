<template>
	<IonApp>
		<IonSplitPane content-id="main-content">
			<ion-menu content-id="main-content" type="overlay">
				<ion-content>
					<ion-list id="inbox-list">
						<ion-list-header>
							<img src="/assets/images/main-logo.png">
						</ion-list-header>
						<ion-note>Entertainment news<br>straight from the source.</ion-note>
						<!-- <ion-note class="full-width"><button @click="shareApp">Share App</button></ion-note> -->
						<ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
							<ion-item @click="selectedIndex = i" router-direction="root" :href="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
								<ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
								<ion-label>{{ p.title }}</ion-label>
							</ion-item>
						</ion-menu-toggle>
						<ion-menu-toggle auto-hide="false">
							<ion-item href="/app/contentpage/contact" lines="none" detail="false" class="hydrated">
								<ion-icon slot="start" :ios=mailOutline :md=mailSharp></ion-icon>
								<ion-label>Contact / Feedback</ion-label>
							</ion-item>
							<!--
							<ion-item @click="shareApp" lines="none" detail="false" class="hydrated">
								<ion-icon slot="start" :ios=shareOutline :md=shareSharp></ion-icon>
								<ion-label><strong>Share App</strong></ion-label>
							</ion-item>
							<ion-item @click="rateApp" lines="none" detail="false" class="hydrated">
								<ion-icon slot="start" :ios=starOutline :md=starSharp></ion-icon>
								<ion-label><strong>Rate App</strong></ion-label>
							</ion-item>
							-->
							<ion-item @click="clearCache" lines="none" detail="false" class="hydrated">
								<ion-icon slot="start" :ios=cloudDownloadOutline :md=cloudDownloadSharp></ion-icon>
								<ion-label>Refesh App Data</ion-label>
							</ion-item>
							<!--
							<ion-item @click="showTabBarBanner" lines="none" detail="false" class="hydrated">
								<ion-icon slot="start" :ios=cloudDownloadOutline :md=cloudDownloadSharp></ion-icon>
								<ion-label>Trigger Test Ad</ion-label>
							</ion-item>
							-->
						</ion-menu-toggle>
					</ion-list>
					<ion-list id="labels-list">
						<ion-list-header>Follow Us</ion-list-header>
						<ion-note>Join the uInterview community by following us on social media.</ion-note>
						<ion-item lines="none">
							<span v-for="(social, index) in socials" :key="index" class="social-link">
								<a :href=social.url><ion-icon slot="start" :ios=social.iosIcon :md=social.mdIcon></ion-icon></a>
							</span>
						</ion-item> 
					</ion-list>
					<ion-list id="labels-list">
						<!--
						<ion-item v-for="(label, index) in labels" lines="none" :key="index">
							<ion-icon slot="start" :ios="bookmarkOutline" :md="bookmarkSharp"></ion-icon>
							<ion-label>{{ label }}</ion-label>
						</ion-item> 
						-->
					</ion-list>
					<ion-note class="bottom-aligned">
						&copy;<span ref="copyYear"></span> uInterview, Inc.<br>
						<a href="/app/contentpage/privacy">Privacy</a> • 
						<a href="/app/contentpage/terms">Terms</a> • 
						<a href="/app/contentpage/masthead">Masthead</a>
						<img src="/assets/images/main-logo.png" class="bottom-logo">
					</ion-note>
				</ion-content>
			</ion-menu>
			<ion-router-outlet id="main-content"></ion-router-outlet>
		</IonSplitPane>
	</IonApp>
</template>

<script lang="ts">
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, bugOutline, bugSharp, cloudDownloadOutline, cloudDownloadSharp, shareOutline, shareSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, chatbubblesOutline, chatbubblesSharp, informationCircleOutline, informationCircleSharp, shieldCheckmarkOutline, shieldCheckmarkSharp, starOutline, starSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, logoFacebook, logoTwitter, logoInstagram, logoYoutube, homeOutline, homeSharp, newspaperOutline, newspaperSharp, videocamOutline, videocamSharp, airplaneOutline, airplaneSharp, shirtOutline, shirtSharp, cartOutline, cartSharp, musicalNotesOutline, musicalNotesSharp, medicalOutline, medicalSharp, documentTextOutline, documentTextSharp, checkboxOutline, checkboxSharp, peopleOutline, peopleSharp, chatboxEllipsesOutline, chatboxEllipsesSharp } from 'ionicons/icons';
import { Plugins } from "@capacitor/core";
import { AppRate } from '@ionic-native/app-rate';
import axios from "axios";
import { jsonDataURL } from '@uinterview/uinterview'

export default defineComponent({
	name: 'App',
	components: {
		IonApp, 
		IonContent, 
		IonIcon, 
		IonItem, 
		IonLabel, 
		IonList, 
		IonListHeader, 
		IonMenu, 
		IonMenuToggle, 
		IonNote, 
		IonRouterOutlet, 
		IonSplitPane
	},
	setup() {
		const selectedIndex = ref(0);
		const appPages = [
			{
				title: 'Home',
				url: '/app/',
				iosIcon: homeOutline,
				mdIcon: homeSharp
			},
			{
				title: 'News',
				url: '/app/category/news',
				iosIcon: newspaperOutline,
				mdIcon: newspaperSharp
			},
			{
				title: 'Videos',
				url: '/app/category/videos',
				iosIcon: videocamOutline,
				mdIcon: videocamSharp
			},
			{
				title: 'Reviews',
				url: '/app/category/reviews',
				iosIcon: starOutline,
				mdIcon: starSharp
			},
			{
				title: 'Music',
				url: '/app/category/music',
				iosIcon: musicalNotesOutline,
				mdIcon: musicalNotesSharp
			},
			{
				title: 'Style',
				url: '/app/category/style',
				iosIcon: shirtOutline,
				mdIcon: shirtSharp
			},
			{
				title: 'uBio',
				url: '/app/category/ubio',
				iosIcon: peopleOutline,
				mdIcon: peopleSharp
			},
			{
				title: 'uShop',
				url: '/app/category/ushop',
				iosIcon: cartOutline,
				mdIcon: cartSharp
			},
			{
				title: 'uTravel',
				url: '/app/category/utravel',
				iosIcon: airplaneOutline,
				mdIcon: airplaneSharp
			},
/*
			{
				title: 'Coronavirus',
				url: '/app/category/coronavirus',
				iosIcon: medicalOutline,
				mdIcon: medicalSharp
			}
*/
		];
		const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
		const socials = [
		{
			name: 'Facebook',
			iosIcon: logoFacebook,
			mdIcon: logoFacebook,
			url:  'http://www.facebook.com/UInterview/'
		}, {
			name: 'Twitter',
			iosIcon: logoTwitter,
			mdIcon: logoTwitter,
			url:  'http://twitter.com/uinterview/'
		}, {
			name: 'Instagram',
			iosIcon: logoInstagram,
			mdIcon: logoInstagram,
			url:  'https://www.instagram.com/uinterview/'
/*
		}, {
			name: 'YouTube',
			iosIcon: logoYoutube,
			mdIcon: logoYoutube,
			url:  'https://www.youtube.com/channel/UCClKR595AEB9Ivt_PrsrKZQ/featured'
*/
		}];
		const path = window.location.pathname.split('page/')[1];
		if (path !== undefined) {
			selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
		}
		const path2 = window.location.pathname.split('category/')[1];
		if (path2 !== undefined) {
			selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path2.toLowerCase());
		}
		
		const route = useRoute();

		return { 
			selectedIndex,
			appPages, 
			labels,
			socials,
			shareOutline,
			shareSharp,
			homeOutline,
			homeSharp,
			newspaperOutline,
			newspaperSharp,
			videocamOutline,
			videocamSharp,
			shirtOutline,
			shirtSharp,
			airplaneOutline,
			airplaneSharp,
			cartOutline,
			cartSharp,
			musicalNotesOutline,
			musicalNotesSharp,
			medicalOutline,
			medicalSharp,
			documentTextOutline,
			documentTextSharp,
			checkboxOutline,
			checkboxSharp,
			peopleOutline,
			peopleSharp,
			chatboxEllipsesOutline,
			chatboxEllipsesSharp,
			logoFacebook,
			logoTwitter,
			logoInstagram,
			logoYoutube,
			archiveOutline, 
			bugSharp, 
			bugOutline, 
			cloudDownloadSharp, 
			cloudDownloadOutline, 
			archiveSharp, 
			bookmarkOutline, 
			bookmarkSharp, 
			heartOutline, 
			heartSharp, 
			mailOutline, 
			mailSharp, 
			chatbubblesOutline, 
			chatbubblesSharp, 
			informationCircleOutline, 
			informationCircleSharp, 
			shieldCheckmarkOutline, 
			shieldCheckmarkSharp, 
			starOutline, 
			starSharp, 
			paperPlaneOutline, 
			paperPlaneSharp, 
			trashOutline, 
			trashSharp, 
			warningOutline, 
			warningSharp,
			isSelected: (url: string) => url === route.path ? 'selected' : ''
		}
	},
	mounted() {
		const d = new Date
		this.$refs.copyYear.innerHTML = d.getFullYear()
		// this.$refs.adsenseContent.innerHTML = document.getElementById('divadsensedisplaynone').innerHTML
	},
	methods: {
		clearCache(){
			if(localStorage.appData) {
				console.log('cleaning cache');
				window.localStorage.removeItem('appData');
				axios.get(jsonDataURL)
					.then(response => {
						this.entriesList = response.data
						localStorage.setItem('appData', JSON.stringify(response.data));
						location.href = '/app/'
					})
			}
		},
		rateApp(){
			/*
			const preferences = AppRate.getPreferences();
			preferences.simpleMode = true;
			preferences.customLocale = {
				title: "Rate the %@ app!",
				message: "It won't take more than a minute, and you'll help us reach more people.",
				cancelButtonLabel: "No, Thanks",
				laterButtonLabel: "Remind Me Later",
				rateButtonLabel: "Rate It Now",
				yesButtonLabel: "Yes!",
				noButtonLabel: "Not really",
				appRatePromptTitle: "Rate the %@ app!",
				feedbackPromptTitle: 'Give us some feedback?',
			}
			preferences.storeAppURL = {
				ios: 'id548529935',
				android: 'market://details?id=com.uinterview.erik',
				// windows: 'ms-windows-store://review/?ProductId=XXXXXXXX',
			};
			AppRate.setPreferences(preferences);
			console.log(preferences)
			AppRate.promptForRating(true);
			*/
		},
		shareApp(){
			const shareOptions = {
				message: 'uInterview - Entertainment news straight from the source',
				subject: 'uInterview - Entertainment news straight from the source',
				files: ['', ''], // an array of filenames either locally or remotely
				url: 'https://uinterview.com/get-app/',
				chooserTitle: 'Share With', // Android only, you can override the default share sheet title
				iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
			};
			const shareOnSuccess = function(result) {
				console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
				console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
			};
			const shareOnError = function(msg) {
				console.log("Sharing failed with message: " + msg);
			};
			window['plugins'].socialsharing.shareWithOptions(shareOptions, shareOnSuccess, shareOnError);
		}
	}
});
</script>

<style scoped>
ion-menu ion-content {
	--background: #1e1e1e;
}
.list-ios {
	background: #1e1e1e;
}
ion-menu ion-content {
	--padding-start: 4px;
	--padding-end: 4px;
	--padding-top: 10px;
	--padding-bottom: 10px;
}

ion-menu ion-list {
	padding: 10px 0;
}

ion-menu ion-note {
	margin-bottom: 30px;
}

ion-menu ion-list-header,
ion-menu ion-note {
	padding-left: 10px;
	padding-right: 10px;
}

ion-menu ion-list#inbox-list {
	border-bottom: 1px solid transparent;
}

ion-menu ion-list#inbox-list ion-list-header {
	font-size: 22px;
	font-weight: 600;
	min-height: 20px;
}
ion-menu.ios ion-item ion-icon {
	color: #aaa;
}

ion-menu ion-list#labels-list ion-list-header {
	font-size: 16px;
	min-height: 26px;
	font-weight: bold;
	color: #fff;
	margin-bottom: 10px;
}

ion-menu ion-item {
	--padding-start: 10px;
	--padding-end: 10px;
	border-radius: 4px;
}

ion-menu ion-item {
	--background: transparent;
}
ion-menu ion-item.selected {
	--background: #5bc2e7;
	color: #fff;
}

ion-menu ion-item.selected ion-icon {
	color: #fff;
}

ion-menu ion-item ion-icon {
	color: #616e7e;
}

ion-menu ion-item ion-label {
	font-weight: 500;
	color: #fff !important;
}

ion-menu.ios ion-content {
	--padding-bottom: 20px;
}

ion-menu.ios ion-list {
	padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
	line-height: 24px;
	margin-bottom: 20px;
}

ion-menu.ios ion-item {
	--padding-start: 16px;
	--padding-end: 16px;
	--min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
	color: #fff;
}

ion-menu.ios ion-item ion-icon {
	font-size: 24px;
	color: rgba(255,255,255,0.6);
}

ion-menu.ios ion-list#labels-list ion-list-header {
	margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
	padding-left: 16px;
	padding-right: 16px;
}

ion-menu.ios ion-note {
	margin-bottom: 8px;
}

ion-note {
	display: inline-block;
	font-size: 16px;
	color: var(--ion-color-medium-shade);
}

ion-item.selected {
	--color: var(--ion-color-primary);
}
</style>